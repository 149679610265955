import {Storage, hash_properties} from '@smartplan/analytics/posthog/storage'
import posthog from 'posthog-js'


/**
 * @typedef {Object} PostHogData
 * @property {boolean} debug
 * @property {string} user_identity
 * @property {Object} user_properties
 * @property {string} account_group_type
 * @property {string} account_group_key
 * @property {Object} account_group_properties
 * @property {string} tracked_distinct_id
 * @property {string} tracked_session_id
 */

/**
 * @typedef {import('posthog-js').BootstrapConfig} BootstrapConfig
 */

/**
 * @param {PostHogData} data
 */
function posthog_identity(data) {
    const user_identity = data.user_identity

    if (!user_identity) {
        return
    }

    // clear out old cookie that stored that full object, remove after June 2025
    Storage.remove(`user_properties_${user_identity}`)

    // user's name and more isn't available before registration is complete
    const user_property_key = `user_properties_hash_${user_identity}`
    const stored_hash = Storage.get(user_property_key)
    const new_hash = hash_properties(data.user_properties)

    if (user_identity && stored_hash === new_hash) {
        return
    }

    posthog.identify(user_identity, data.user_properties)
    Storage.set(user_property_key, new_hash)
}

/**
 * @param {PostHogData} data
 */
function posthog_group(data) {
    const {account_group_type, account_group_key, account_group_properties} = data

    if (account_group_key === null) {
        return
    }

    // clear out old cookie that stored that full object, remove after June 2025
    Storage.remove(`account_properties_${account_group_key}`)

    const current_groups = posthog.getGroups()
    const is_same_group = account_group_type in (current_groups ?? []) && current_groups[account_group_type] === account_group_key

    const account_properties_key = `account_properties_hash_${account_group_key}`
    const stored_hash = Storage.get(account_properties_key)
    const new_hash = hash_properties(account_group_properties)

    if (is_same_group && stored_hash === new_hash) {
        return
    }

    posthog.group(account_group_type, account_group_key, account_group_properties)
    Storage.set(account_properties_key, new_hash)
}

/**
 * Perform reset on logout
 */
function setup_logout_handler() {
    document.addEventListener('DOMContentLoaded', () => {
        document.querySelectorAll(".logout-nav").forEach((element) => {
            element.addEventListener("click", () => posthog.reset())
        })
    })
}

/**
 * @param {PostHogData} data
 * @return {BootstrapConfig}
 */
function bootstrap_config(data) {
    const config = {}

    // uses names identical to what posthog recommends
    const hashParams = new URLSearchParams(window.location.hash.substring(1))

    const distinct_id = data.tracked_distinct_id || hashParams.get('distinct_id')
    if (distinct_id) {
        config.distinctID = distinct_id
    }

    const session_id = data.tracked_session_id || hashParams.get('session_id')
    if (session_id) {
        config.sessionID = session_id
    }

    return config
}

/**
 * @param {string} api_key
 * @param {string} api_host
 * @param {PostHogData} data
 */
export default function init_posthog(api_key, api_host, data) {
    posthog.init(api_key, {
        api_host: api_host,
        debug: data.debug,
        autocapture: false,
        bootstrap: {
            ...bootstrap_config(data),
        },
        loaded: function () {
            posthog_identity(data)
            posthog_group(data)
            setup_logout_handler()
        },
    });
}

window.posthog = posthog
