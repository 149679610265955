import Cookies from "js-cookie";

/**
 * @param {string} hostname
 * @returns {string}
 * @private only exported for testing
 */
export function cookie_domain(hostname) {
    const parts = hostname.split('.')
    if (parts.length < 2) {
        // special domain, e.g. localhost
        return hostname
    }

    const [domain, tld] = hostname.split('.').slice(-2)

    return `.${domain}.${tld}`
}

// use cookie backend to keep data across subdomains
const cookies = Cookies.withAttributes({
    expires: 365,
    domain: cookie_domain(window.location.hostname),
})

export class Storage {
    static get(key) {
        return cookies.get(`pha_${key}`)
    }

    static set(key, value) {
        return cookies.set(`pha_${key}`, value)
    }

    static remove(key) {
        return cookies.remove(`pha_${key}`)
    }
}

/**
 * @param {Object} object
 * @returns {string}
 */
export function hash_properties(object) {
    // sort top-level keys for consistency
    let json = JSON.stringify(object, Object.keys(object).toSorted())

    let hash = 0

    for (let i = 0; i < json.length; i++) {
        let char = json.charCodeAt(i)
        hash = (hash * 31) + char // positional weighting
        // eslint-disable-next-line no-bitwise
        hash |= 0 // ensure this is still a 32-bit integer
    }

    return hash.toString()
}
